import { PrimaryButton, Stack, StackItem } from "@fluentui/react";
import './brandschutz-page.css'
import pic from '../../../assets/pictures/TKB_Schweißarbeiten_brandschutz.jpg'
import { useEffect } from "react";

interface BrandschutzsPageProps {
  name: string,
  sendMail: (path: string) => void
}

function Brandschutz(props: BrandschutzsPageProps) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="content-page">
      <img className="image fireImg background" src={pic} loading="lazy" />
      <Stack style={{ zIndex: 1, marginTop: 900, marginBottom: 10 }}>
        <StackItem>
          <p className="heading">{props.name}</p>
        </StackItem>
      </Stack>
      <Stack>
        <StackItem>
          <p className="fire-heading ">Unsere Brandschutzbeauftragten unterstüzen Sie gerne bei:</p>
        </StackItem>
      </Stack>
      <Stack wrap style={{ height: 300 }}>
        <span className="fire-listing">Brandschutzbegehungen</span>
        <span className="fire-listing">Erstellen von Brandschutzordnungen Teil A, B und C</span>
        <span className="fire-listing">Schulung von Brandschutzhelfern mit praktischer Löschübung</span>
        <span className="fire-listing">Unterweisung der Angestellten</span>
        <span className="fire-listing">Evakuierungsübungen</span>
      </Stack>
      <PrimaryButton className="send-mail" onClick={() => props.sendMail('Anfrage zum Thema Brandschutz')}>Angebot anfragen</PrimaryButton>
    </div>
  );
}
export default Brandschutz;