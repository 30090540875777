import logo from '../../tkb-logo.svg';
import './header.css';
import { CommandBar, ICommandBarItemProps, IStackStyles, Stack } from "@fluentui/react";
import { Items } from "./menu-items";

const stackStyle: IStackStyles = {
  root: {
    display: 'flex',
    overflow: "hidden"
  },
};

interface MenuProps {
  navigate: (path: string) => void
}

function Header(props: MenuProps) {

  const menuItems: ICommandBarItemProps[] = Items({ navigate: props.navigate });

  return (
    <div className="menu-bar">
      <Stack horizontal styles={stackStyle}>
        <Stack.Item grow={0.2}>
          <button className="logo-button" onClick={() => props.navigate('Arbeitssicherheit')}>
            <img src={logo} className="logo" alt="logo" />
          </button>
        </Stack.Item>
        <Stack.Item grow={10}>
          <CommandBar items={menuItems} />
        </Stack.Item>
      </Stack>
    </div>
  );
}
export default Header;