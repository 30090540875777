import { Stack, StackItem } from "@fluentui/react";
import './impressum.css'
import { useEffect } from "react";

interface ImpressumPageProps {
  id: string,
  name: string,
}

function Impressum(props: ImpressumPageProps) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="impressum-content">
      <Stack>
        <StackItem>
          <p className="heading">{props.name}</p>
        </StackItem>
        <StackItem className="text-section">
          <p className="impressum-heading">GESETZLICHER VERTRETER</p>
          <p className="impressum-text">Thomas Kümmel & Thomas Brand</p>
        </StackItem>
        <StackItem className="text-section">
          <p className="impressum-heading">Adresse</p>
          <p className="impressum-text">TKB-ASIG GmbH<br />
            Falchstraße 46<br />
            70378 Stuttgart<br />
            info@tkb-asig.de<br />
            www.tkb-asig.de
          </p>
        </StackItem>
        <StackItem className="text-section">
          <p className="impressum-heading">HANDELS-, VEREINS-, GENOSSENSCHAFTS- ODER PARTNERSCHAFTSREGISTERANGABEN</p>
          <p className="impressum-text">Amtsgericht Stuttgart HRB 772727</p>
        </StackItem>
        <StackItem className="text-section">
          <p className="impressum-heading">Umsatzsteueridentifikationsnummer</p>
          <p className="impressum-text">DE328837089</p>
        </StackItem>
        <StackItem className="text-section">
          <p className="impressum-heading">Haftung</p>
          <p className="impressum-text">Diese Website wurde mit größtmöglicher Sorgfalt zusammengestellt.
            Trotzdem kann keine Gewähr für die Fehlerfreiheit und Genauigkeit der enthaltenen Informationen übernommen werden.
            Jegliche Haftung für Schäden, die direkt oder indirekt aus der Benutzung dieser Website entstehen,
            wird ausgeschlossen, soweit diese nicht auf Vorsatz oder grober Fahrlässigkeit beruhen.
            Der Betreiber übernimmt keinerlei Gewähr für die Richtigkeit, Vollständigkeit, Aktualität oder Qualität der Veröffentlichten
            und somit verfügbaren Informationen. Sofern von dieser Website auf andere Internetseiten verwiesen wird,
            die von Dritten betrieben werden, übernimmt der Herausgeber keine Verantwortung für deren Inhalte.
            Der Herausgeber ist ab dem Moment wo die Webseite, durch Navigation über den Hinterlegten Verweis auf einem Drittanbieter,
            verlassen wird von jeglicher Haftung befreit.
            Der Herausgeber gibt sich die größtmögliche Mühe in allen Veröffentlichungen die Urheberrechte der verwendeten Materialien zu beachten,
            die von ihm selbst angefertigte Materialien zu nutzen oder auf lizenzfreie Materialien zurückzugreifen.
            Das Copyright für veröffentlichte, vom Betreiber selbst angefertigte Materialien (Texte, Medien) bleibt allein beim Betreiber der Seiten.
            Eine Vervielfältigung oder Verwendung solcher Materialien in anderen elektronischen oder gedruckten Medien und Publikationen
            ist ohne die ausdrückliche Erlaubnis des Betreibers nicht gestattet.</p>
        </StackItem>
      </Stack>
    </div>
  );
}
export default Impressum;