import { PrimaryButton, Stack, StackItem } from "@fluentui/react";
import './karriere-page.css'
import pic from '../../../assets/pictures/TKB_Unterschrift_karriere.jpg'
import { useEffect } from "react";

interface KarrieresPageProps {
  name: string,
  sendMail: (path: string) => void
}

function Karriere(props: KarrieresPageProps) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="content-page">
      <img className="image workImg background" src={pic} loading="lazy" />
      <Stack style={{ zIndex: 1, marginTop: 900, marginBottom: 10 }}>
        <StackItem>
          <p className="heading">{props.name}</p>
        </StackItem>
        <StackItem className="text-section">
          <p className="job-text">Wir sind immer auf der Suche nach freiberuflichen Betriebsärtzte, SiFas und Brandschutzbeauftragte.
            Erhalten wir einen Vertrag den wir nicht selbst bearbeiten können (z.B. weil wir nicht im jeweiligen Bundesland vertreten sind)
            vergeben wir den Vertrag an unser Netzwerk, natürlich in Rücksprache mit unseren Kunden</p>
        </StackItem>
      </Stack>
      <PrimaryButton className="send-mail" onClick={() => props.sendMail('Stellen Anfrage')}>Klingt Interessant</PrimaryButton>
    </div>
  );
}
export default Karriere;