import { ICommandBarItemProps, IContextualMenuItem } from "@fluentui/react"

interface MenuProps {
  navigate: (path: string) => void
}

export function Items(props: MenuProps) {

  const leistungen: IContextualMenuItem[] = [
    {
      key: 'Arbeitssicherheit',
      name: 'Arbeitssicherheit',
      url: '#/Arbeitssicherheit',
      style: { color: '#c99d03', fontSize: 20 },
      onClick: () => { props.navigate('Arbeitssicherheit') }
    },
    {
      key: 'Brandschutz',
      name: 'Brandschutz',
      url: '#/Brandschutz',
      style: { color: '#c99d03', fontSize: 20 },
      onClick: () => { props.navigate('Brandschutz') }
    },
    {
      key: 'Prüfungen',
      name: 'Prüfungen',
      url: '#/Pruefungen',
      style: { color: '#c99d03', fontSize: 20 },
      onClick: () => { props.navigate('Pruefungen') }
    },
    {
      key: 'Aus- & Weiterbildung',
      name: 'Aus- & Weiterbildung',
      url: '#/AusUWeiterbildung',
      style: { color: '#c99d03', fontSize: 20 },
      onClick: () => { props.navigate('Bildung') }
    },
  ]

  const karriere: IContextualMenuItem[] = [
    {
      key: 'Offene Stellen',
      name: 'Offene Stellen',
      url: '#/OffeneStellen',
      style: { color: '#c99d03', fontSize: 20 },
      onClick: () => { props.navigate('Karriere') }
    },
  ]

  const menuItems: ICommandBarItemProps[] = [
    {
      key: 'Leistungen',
      title: 'Leistungen',
      text: 'Leistungen',
      subMenuProps: {
        items: leistungen
      },
      style: { color: '#c99d03', fontSize: 40, marginTop: 50, paddingRight: 15, paddingLeft: 15, },
    },
    {
      key: 'Karriere',
      title: 'Karriere',
      text: 'Karriere',
      expandAriaLabel: 'Show more Karriere components',
      subMenuProps: {
        items: karriere
      },
      style: { color: '#c99d03', fontSize: 40, marginTop: 50, paddingRight: 15, paddingLeft: 15, },
    },
    {
      key: 'Kontakt',
      title: 'Kontakt',
      text: 'Kontakt',
      expandAriaLabel: 'Show more Kontakt',
      onClick: () => { props.navigate('Kontakt') },
      style: { color: '#c99d03', fontSize: 40, marginTop: 50, paddingRight: 15, paddingLeft: 15, },
    },
  ]

  return menuItems;
}

