import { Stack, StackItem } from "@fluentui/react";
import './kontakt-page.css'
import pic from '../../../assets/pictures/TKB_Sekritärin_contact.jpg'
import { useEffect } from "react";

interface KontaktsPageProps {
  id: string,
  name: string,
}

function Kontakt(props: KontaktsPageProps) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="content-page">
      <img className="image contactImg background" src={pic} loading="lazy" />
      <Stack style={{ zIndex: 1, marginTop: 900, marginBottom: 10 }}>
        <StackItem>
          <p className="heading">{props.name}</p>
        </StackItem>
        <StackItem>
          <p className="contact-mail">info@tkb-asig.de</p>
        </StackItem>
        <StackItem>
          <p className="contact-text">TKB-ASIG GmbH</p>
          <p className="contact-text">Falchstr. 46</p>
          <p className="contact-text">70378 Stuttgart</p>
        </StackItem>
      </Stack>
    </div>
  );
}
export default Kontakt;