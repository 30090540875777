import { useState } from 'react';
import './App.css';
import { ThemeProvider, createTheme } from '@fluentui/react';
import Header from './components/header-component/header';
import Arbeitssicherheit from './components/page-component/arbeitssicherheit/arbeitssicherheit-page';
import Brandschutz from './components/page-component/brandschutz/brandschutz-page';
import Pruefungen from './components/page-component/pruefungen/pruefungen-page';
import Bildung from './components/page-component/bildung/bildung-page';
import Karriere from './components/page-component/karriere/karriere-page';
import Kontakt from './components/page-component/kontakt/kontakt-page';
import Impressum from './components/page-component/impressum/impressum';
import Footer from './components/footer-component/footer';
import Agb from './components/page-component/agb/agb';

const myTheme = createTheme({
  palette: {
    themePrimary: '#ddae07',
    themeLighterAlt: '#fefcf4',
    themeLighter: '#faf1d3',
    themeLight: '#f5e6ae',
    themeTertiary: '#ebcd62',
    themeSecondary: '#e2b821',
    themeDarkAlt: '#c89e06',
    themeDark: '#a98505',
    themeDarker: '#7c6204',
    neutralLighterAlt: '#413f3f',
    neutralLighter: '#3f3e3e',
    neutralLight: '#3d3c3c',
    neutralQuaternaryAlt: '#393838',
    neutralQuaternary: '#363535',
    neutralTertiaryAlt: '#343333',
    neutralTertiary: '#c8c8c8',
    neutralSecondary: '#d0d0d0',
    neutralSecondaryAlt: '#d0d0d0',
    neutralPrimaryAlt: '#dadada',
    neutralPrimary: '#ffffff',
    neutralDark: '#f4f4f4',
    black: '#f8f8f8',
    white: '#424141',
  }
});


function App() {

  const [displayComponent, setComponent] = useState(<Arbeitssicherheit name='Arbeitsschutz' sendMail={sendMail} />)

  function sendMail(subject: string) {
    var mail = `mailto:info@tkb-asig.de?subject=${subject}`
    window.location.href = mail;
  }

  function swapComponent(componentName: string) {
    var result = <div></div>;
    switch (componentName) {
      case 'Arbeitssicherheit':
        result = <Arbeitssicherheit name='Arbeitsschutz' sendMail={sendMail} />
        break;
      case 'Brandschutz':
        result = <Brandschutz name='Brandschutz' sendMail={sendMail} />
        break;
      case 'Pruefungen':
        result = <Pruefungen name='Prüfungen' sendMail={sendMail} />
        break;
      case 'Bildung':
        result = <Bildung name='Aus- & Weiterbildung' sendMail={sendMail} />
        break;
      case 'Karriere':
        result = <Karriere name='Offene Stellen' sendMail={sendMail} />
        break;
      case 'Kontakt':
        result = <Kontakt id='Kontakt' name='Kontakt' />
        break;
      case 'Impressum':
        result = <Impressum id='Impressum' name='Impressum' />
        break;
      case 'AGB':
        result = <Agb id='AGB' name='AGB' />
        break;
    }
    setComponent(result);
  }

  return (
    <ThemeProvider applyTo='body' theme={myTheme}>
      <div className="app">
        <Header navigate={swapComponent} />
        <body className="app-content">
          {displayComponent}
        </body>
        <Footer navigate={swapComponent} />
      </div>
    </ThemeProvider>
  );
}

export default App;
