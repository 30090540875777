import { Stack, StackItem } from "@fluentui/react";
import './agb.css'
import { useEffect } from "react";

interface AgbPageProps {
  id: string,
  name: string,
}

function Agb(props: AgbPageProps) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="impressum-content">
      <Stack>
        <StackItem>
          <p className="heading" style={{ textAlign: "center" }}>{props.name}</p>
        </StackItem>
        <StackItem className="text-section">
          <p className="agb-heading">ALLGEMEINE GESCHÄFTS- UND
            SCHULUNGSBEDINGUNGEN</p>
          <p className="agb-text">Für die Durchführung von Schulungen-/ Unterweisungen sowie Prüfungen bedarf
            es der Schriftform und ist verbindlich. Hierbei werden die Rahmenbedingungen der
            TKB ASIG GmbH anerkannt. Rechnungen sind innerhalb von vier Wochen nach
            Rechnungsstellung ohne Abzug zur Zahlung fällig.</p>
          <p className="agb-text">
            Können Teilnehmerin oder Teilnehmer die Veranstaltungen nicht wahrnehmen, gilt<br />
            a) Ersatzteilnehmer können unter Einhaltung der Rahmenbedingen einspringen,<br />
            b) bei Absagen am Veranstaltungstag sowie fehlende schriftliche Absagen werden
            100% in Rechnung gestellt,<br />
            c) bei Absagen von zwei Werktagen vor der Veranstaltung werden 50 % der Teilnahmekosten berechnet
            Werden ganze Veranstaltungen inkl. Prüfungstermine binnen zwei Werktage vor
            der geplanten Veranstaltung abgesagt, behält sich die TKB-ASIG GmbH, vor 100%
            der Teilnahmekosten in Rechnung zustellen. Die geplanten Fahrtkosten entfallen.</p>
          <p className="agb-text">
            Die TKB-ASIG GmbH behält sich vor Veranstaltungen abzusagen, wenn wichtige
            Gründe (z.B. geringen Teilnehmerzahl, Ausfall der Referenten) entgegenstehen.
            Hieraus anfallende Ansprüche können nicht geltend gemacht werden.
            Sollten einzelne Bestimmungen dieses Vertrages unwirksam sein oder werden,
            wird hierdurch die Wirksamkeit des Vertrages im Übrigen nicht berührt.
            Gerichtsstand ist Stuttgart. </p>
        </StackItem>
      </Stack>
    </div>
  );
}
export default Agb;