import './footer.css';
import { IStackStyles, Link, Stack } from "@fluentui/react";


const stackStyle: IStackStyles = {
  root: {
    display: 'flex',
    overflow: "hidden"
  },
};

interface MenuProps {
  navigate: (path: string) => void
}

function Footer(props: MenuProps) {

  return (
    <div className="footer-bar">
      <Stack horizontal styles={stackStyle}>
        <Stack.Item grow={10}>
          <Link className="footer-link" onClick={() => props.navigate('Impressum')}>Impressum</Link>
          <Link className="footer-link" onClick={() => props.navigate('AGB')}>AGB</Link>
        </Stack.Item>
      </Stack>
    </div>
  );
}
export default Footer;