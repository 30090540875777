import { PrimaryButton, Stack, StackItem } from "@fluentui/react";
import './pruefungen-page.css'
import pic from '../../../assets/pictures/TKB_Regallager_pruefung.jpg'
import { useEffect } from "react";

interface PruefungensPageProps {
  name: string,
  sendMail: (path: string) => void
}

function Pruefungen(props: PruefungensPageProps) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="content-page">
      <img className="image schoolImg background" src={pic} loading="lazy" />
      <Stack style={{ zIndex: 1, marginTop: 700, marginBottom: 10 }}>
        <StackItem>
          <p className="heading">{props.name}</p>
        </StackItem>
        <StackItem>
          <p className="test-heading">Unsere Experten unterstüzen Sie gerne bei den Prüfungen von:</p>
        </StackItem>
        <Stack horizontal style={{ alignSelf: "center" }}>
          <StackItem className="test-listing">
            <p>Regalen</p>
            <p>Leitern und Tritten</p>
            <p>Lastaufnahmemitteln</p>
          </StackItem>
        </Stack>
      </Stack>
      <PrimaryButton className="send-mail" onClick={() => props.sendMail('Anfrage zur Regal-/Leiter-/Lastaufnahmeprüfung')}>Angebot anfragen</PrimaryButton>
    </div>
  );
}
export default Pruefungen;