import { PrimaryButton, Stack, StackItem } from "@fluentui/react";
import './arbeitssicherheit-page.css'
import '../../../assets/globalStyles/global.css';
import pic from '../../../assets/pictures/TKB_Kacheln_arbeitssicherheit.jpg'
import { useEffect } from "react";

interface ArbeitssicherheitsPageProps {
  name: string,
  sendMail: (path: string) => void
}

function Arbeitssicherheit(props: ArbeitssicherheitsPageProps) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="content-page">
      <img className="image safeImg background" src={pic} loading="lazy" />
      <Stack style={{ zIndex: 1, marginTop: 900, marginBottom: 10 }}>
        <StackItem>
          <p className="heading">{props.name}</p>
        </StackItem>
        <StackItem>
          <p className="work-heading">Unsere Sifas/Fachkräfte für Arbeitssicherheit unterstüzen Sie gerne bei:</p>
        </StackItem>
      </Stack>
      <Stack wrap style={{ height: 245 }}>
        <span className="work-listing">Gefährdungsbeurteilingen</span>
        <span className="work-listing">Betriebsanweisungen</span>
        <span className="work-listing">Unterweisungen</span>
        <span className="work-listing">ASA (Arbeitsschutzausschuss)</span>
        <span className="work-listing">Unfallanalysen & -meldungen</span>
        <span className="work-listing">Sicherheitstechnischen Begehungen</span>
        <span className="work-listing">Orientierenden Messungen z.B. Lärm, Licht</span>
      </Stack>
      <PrimaryButton className="send-mail" onClick={() => props.sendMail('Anfrage zur Arbeitssicherheit')}>Angebot anfragen</PrimaryButton>
    </div>
  );
}
export default Arbeitssicherheit;