import { PrimaryButton, Stack, StackItem } from "@fluentui/react";
import './bildung-page.css'
import pic from '../../../assets/pictures/TKB_Seminarteilnehmerin_schulung.jpg'
import { useEffect } from "react";

interface BildungsPageProps {
  name: string,
  sendMail: (path: string) => void
}

function Bildung(props: BildungsPageProps) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="content-page">
      <img className="image buildungImg background" src={pic} loading="lazy" />
      <Stack style={{ zIndex: 1, marginTop: 900, marginBottom: 10 }}>
        <StackItem>
          <p className="heading">{props.name}</p>
        </StackItem>
        <StackItem className="buildung-headline">
          <p className="buildung-heading">Wir kommen zu Ihnen für die Unterweisungen sowie Aus- und Weiterbildungen in den Bereichen:</p>
        </StackItem>
      </Stack>
      <Stack wrap style={{ height: 245 }}>
        <span className="buildung-listing">Arbeitsschutz</span>
        <span className="buildung-listing">Brandschutzhelfer</span>
        <span className="buildung-listing">Führungskräfte im Arbeitsschutz</span>
        <span className="buildung-listing">Gabelstapler</span>
        <span className="buildung-listing">Kranführer</span>
        <span className="buildung-listing">Ladungssicherung</span>
        <span className="buildung-listing">Sicherheitsbeauftragte</span>
      </Stack>
      <PrimaryButton className="send-mail" onClick={() => props.sendMail('Aus-/Weiterbildungsanfrage')}>Angebot anfragen</PrimaryButton>
    </div>
  );
}
export default Bildung;